import { CirceRegularFamily } from 'constants/fontsFamily'
import { ColorsTheme } from 'constants/announces/ColorTheme'
import { FontSize, Indent, SizeViewport } from 'constants/sizes'
import { styled } from 'linaria/react'

export const Notice = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 840px;
`

export const Title = styled.div`
  font-size: ${FontSize.bigTitle}px;
  font-family: ${CirceRegularFamily}, Arial, serif;
  font-weight: 700;
`

export const Description = styled.div`
  font-size: ${FontSize.noteAutoComplete}px;
  line-height: 20px;
`

export const Image = styled.div`
  width: 100%;
  max-width: 335px;
  margin: 0 auto;
`

export const Grid = styled.div`
  display: flex;
  min-height: 100%;
  width: 100%;
  align-content: center;
  justify-content: center;
  background-color: ${ColorsTheme.primaryText};
  color: ${ColorsTheme.white};
  padding: 0 ${Indent.indent20}px;
  position: relative;
  z-index: 20;
`

export const Desc = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 820px;
  font-size: ${FontSize.base}px;
  text-align: center;
  position: relative;
  flex-grow: 1;
  margin: 0 auto;
`

export const ImageRules = styled.div`
  width: 100%;
  max-width: 340px;
`

export const Close = styled.div`
  position: absolute;
  right: 0;
  top: 20px;
  cursor: pointer;
`

export const Email = styled.span`
  color: ${ColorsTheme.primaryDefault};
  white-space: nowrap;
`

export const ListAnnounceCreate = styled.div`
  padding: 0 ${Indent.indent32}px;

  & [data-name='announce-not-link'] {
    text-align: left;
  }

  @media screen and (max-width: ${SizeViewport.phone}px) {
    padding: 0;
  }
`

export const PaymentDesc = styled.div`
  color: ${ColorsTheme.primaryDefaultLight};
  font-size: ${FontSize.noteAutoComplete}px;
  margin-top: ${Indent.indent20}px;
`

export const PaymentDescField = styled.div`
  margin: ${Indent.indent4}px 0;
`
